<template>
  <div class="title">Confirmtion</div>
  <div class="header">
    <div class="number">
      <div class="label">Confirmation#</div>
      <div class="value">1536154717</div>
    </div>
    <div class="status">
      <div class="label">Status</div>
      <div class="value">CONFIRMED</div>
    </div>
  </div>
  <div class="guest">
    <div class="details">
      <div class="label">Guest Name</div>
      <div class="value">Rohit Poduval</div>
    </div>
  </div>
  <div class="stay">
    <div class="number">
      <div class="label">Stay Dates</div>
      <div class="value">Jan 20 - Jan 31, 2025</div>
    </div>
  </div>
  <div class="Rate Details">
    <div class="number">
      <div class="label">Room Rate</div>
      <div class="value">$299.90</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirmation",
  components: {},
  data() {
    return {};
  },
};
</script>
