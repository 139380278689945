<template>
  <div>
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Enter fund name or code"
    />
    <el-button @click="fetchFunds">Search</el-button>

    <div v-if="funds.length > 0" class="card-container">
      <h3>Search Results:</h3>
      <el-row gutter="20">
        <el-col
          v-for="fund in funds"
          :key="fund.id"
          :span="8"  
        >
          <el-card class="fund-card">
            <div  class="card-header">
              <span>{{ fund.schemeName }}</span>
            </div>
            <div class="card-body">
              <p>Code: {{ fund.schemeCode }}</p>
              <p>Other details can go here...</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <p v-else-if="searchExecuted">No funds found.</p>
  </div>
</template>

<script>
import { ElButton, ElCard, ElRow, ElCol } from 'element-plus';

export default {
  components: {
    ElButton,
    ElCard,
    ElRow,
    ElCol,
  },
  data() {
    return {
      searchQuery: '',
      funds: [],
      searchExecuted: false,
    };
  },
  methods: {
    async fetchFunds() {
      if (!this.searchQuery) return; // Prevent empty queries
      try {
        const response = await this.$axios.get('/mf/search', {
          params: { q: this.searchQuery },
        });
        this.funds = response.data; // Adjust based on the actual response structure
        this.searchExecuted = true; // Indicate a search was executed
      } catch (error) {
        console.error('Error fetching funds:', error);
      }
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
}

.fund-card {
  margin-bottom: 20px; /* Space between cards */
}

.card-header {
  font-weight: bold;
  font-size: 1.2em;
}

.card-body {
  padding: 10px;
}
</style>
