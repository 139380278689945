<template>
  <div
    class="work"
    v-for="(item, index) in jobs"
    :key="index"
    :style="{ borderLeftColor: item.color }"
    :class="{ expanded: expandedCard === index }"
    @click="toggleCard(index)"
  >
    <transition name="expand">
      <el-row>
        <el-col :span="24">
          <div class="card-header">{{ item.title }}</div>
          <div class="sub-header-1">{{ item.company }}</div>
          <div class="sub-header">{{ item.location }}</div>
          <div class="sub-header">
            {{ item.formattedStart }} - {{ item.formattedEnd }} ·
            {{ item.timeRange }}
          </div>
        </el-col>
      </el-row>
    </transition>
    <transition class="expand">
      <div
        class="card-details"
        v-if="expandedCard === index"
        v-html="item.details"
      ></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Work",

  data() {
    return {
      jobs: [
        {
          id: 1,
          details: null,
          company: "Oracle India",
          title: "Product Manager",
          start: "2024-10-28",
          color: "#c74634",
          end: null,
          location: "Nagpur, Maharashtra, India · Remote",
        },
        {
          id: 2,
          company: "HotelKey Inc",
          details:
            "<ul>\n  <li>Led cross-functional teams comprising business, technical, and creative experts to deliver seamless B2B/B2C user experiences.</li>\n <li>Played a pivotal role in optimising HotelKey's POS and eCommerce products by applying deep industry knowledge and understanding customer behaviour to enhance user experiences and drive digital revenue growth.</li>\n <li>Utilised project management tools such as Jira to streamline workflows, enhance team collaboration, and ensure project success.</li>\n  <li>Leveraged tools like Google Analytics, Sojern, and Adobe Launch to extract actionable insights, identify user learning curves, and reduce friction within the PMS application.</li>\n  <li>Utilised project management tools such as Jira to streamline workflows, enhance team collaboration, and ensure project success.</li>\n",
          title: "Business Analyst",
          start: "2023-01-10",
          color: "#409eff",
          end: "2024-10-18",
          location: "Carrollton, Texas, Unites States · On-site",
        },
        {
          id: 3,
          company: "HotelKey India",
          title: "Product Manager/UX Designer",
          details:
            "<ul>\n    <li>Directed the design and development of a robust, highly customizable booking engine tailored for independent and mid-market hotel chains.</li>\n  <li>Collaborated with API engineers to produce clear and comprehensive documentation, enabling third-party vendors to effectively integrate with the HotelKey Platform.</li>\n  <li>Partnered with UX designers to create an in-house design system, ensuring consistent design language across both internal and client-facing applications within the HotelKey Platform.</li> <li>Spearheaded a design overhaul of the in-house revenue management system, significantly improving usability and enhancing communication with distribution systems and channel managers.</li></ul>",
          start: "2017-10-01",
          color: "#409eff",
          end: "2023-01-09",
          location: "Nagpur, Maharashtra, India · On-site",
        },
        {
          id: 4,
          company: "HotelKey India",
          title: "Implementation Consultant",
          start: "2016-11-25",
          details:
            "<ul>\n  <li>Worked in a team of 6 to deploy HotelKey PMS across 1400 G6 Hospitality Hotels</li>\n  <li>Worked towards designing and testing in-house ETL solutions for migrating legacy hotel data during onboarding.</li>\n  <li>Developed process and checklists to ensure step-by-step migration and planned training and support process.</li>\n</ul>",
          color: "#409eff",
          end: "2017-09-30",
          location: "Nagpur, Maharashtra, India · On-site",
        },
      ],
      expandedCard: null,
    };
  },
  methods: {
    toggleCard(index) {
      this.expandedCard = this.expandedCard === index ? null : index;
      console.log(this.expandedCard); // Toggle the expanded state
    },
    convertMarkdown(markdown) {
      try {
        return window.marked(markdown); // Should be correct if 'marked' is imported properly
      } catch (error) {
        console.error("Error converting markdown:", error);
        return "Rohit"; // Return an empty string if there's an error
      }
    },
  },

  beforeMount() {
    this.jobs.forEach((job) => {
      const startDate = new Date(job.start);
      const endDate = job.end ? new Date(job.end) : new Date(); // Use current date if `end` is null

      // Format start and end dates
      const formatDate = (date) =>
        date.toLocaleString("default", { month: "short", year: "numeric" });

      job.formattedStart = formatDate(startDate);
      job.formattedEnd = job.end ? formatDate(endDate) : "Present";

      // Calculate time range
      const totalMonths =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth());
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      // Create formatted time range
      if (years > 0) {
        job.timeRange = `${years} yr${years > 1 ? "s" : ""} ${
          months > 0 ? `${months} mo${months > 1 ? "s" : ""}` : ""
        }`.trim();
      } else {
        job.timeRange = `${months} mo${months > 1 ? "s" : ""}`;
      }
    });
  },
};
</script>

<style scoped>
.card-title {
  font-size: 18px;
  font-weight: bold;
}

.card-header {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 2px;
}

.sub-header-1 {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 2px;
}

.sub-header {
  font-size: 14px;
  padding-bottom: 2px;
}

.el-row {
  text-align: left;
}

.work {
  border: 1px solid #eaeaea;
  border-left-width: 4px;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
  cursor: pointer; /* Changes the cursor to a selection hand */
  transition: transform 0.3s ease;
}
.card:hover {
  transform: scale(1.05);
}

.card-details {
  margin-top: 16px;
  overflow: hidden;
  text-align: justify;
}

/* Transition for expanding details */
.expand-enter-active,
.expand-leave-active {
  transition: max-height 1s ease, opacity 1s ease;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to,
.expand-leave {
  max-height: 100px; /* Adjust based on content height */
  opacity: 1;
}
</style>
