<template>
  <div class="checkout">
    <div class="contact">
      <div class="header">Contact Information</div>
      <el-form :model="form" label-width="120px" class="guest-form">
        <el-form-item
          label-position="top"
          label="First Name"
          :rules="[
            {
              required: true,
              message: 'Please input first name',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            size="large"
            v-model="form.firstName"
            placeholder="Enter first name"
          />
        </el-form-item>

        <el-form-item
          label-position="top"
          label="Last Name"
          :rules="[
            {
              required: true,
              message: 'Please input last name',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            size="large"
            v-model="form.lastName"
            placeholder="Enter last name"
          />
        </el-form-item>

        <el-form-item
          label-position="top"
          label="Phone"
          :rules="[
            {
              required: true,
              message: 'Please input phone number',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            size="large"
            v-model="form.phone"
            placeholder="Enter Phone Number"
            class="input-with-select"
          >
            <template #prepend>
              <el-select
                v-model="form.code"
                placeholder="Select"
                style="width: 90px"
                size="large"
              >
                <el-option label="+91" value="+91" />
                <el-option label="+1" value="2" />
                <el-option label="+383" value="3" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item
          label-position="top"
          label="Email"
          :rules="[
            {
              required: true,
              message: 'Please input email address',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            size="large"
            v-model="form.email"
            placeholder="Enter email address"
          />
        </el-form-item>

        <el-form-item label-position="top" label="Special Remarks">
          <el-input
            size="large"
            type="textarea"
            v-model="form.remarks"
            placeholder="Enter special remarks"
            :rows="4"
          />
        </el-form-item>
      </el-form>

      <div class="payment">
        <div class="header">Payment Information</div>
        <div>
          <form @submit.prevent="handleSubmit">
            <div ref="paymentElement"></div>
            <button
              class="pay"
              type="submit"
              v-if="clientSecret"
              :disabled="loading"
            >
              {{ loading ? "Processing..." : "Pay Now" }}
            </button>
          </form>
          <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
        </div>
      </div>
    </div>

    <div class="summary">
      <div class="header">Sumamry</div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "GuestForm",
  components: {},
  data() {
    return {
      clientSecret: null,
      form: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        remarks: null,
        code: "+91",
      },
    };
  },

  async mounted() {
    // Fetch the client secret from your backend
    const response = await fetch(
      "https://rohit-ux.vercel.app/api/create-payment-intent",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: 1000 }), // Example amount in cents ($10.00)
      }
    );

    const { clientSecret } = await response.json();
    this.clientSecret = clientSecret;

    // Initialize Stripe
    this.stripe = await loadStripe(
      "pk_test_51QUQQ5Cc9zRMo6lbrAsXj4zmJEqABNWpoRym6xu2HMvC2BGsT073BRxYr7S0w0UDaPCwT3vze8rVECCxmj63RFpI00ILu4PDc5"
    ); // Replace with your Stripe public key
    this.elements = this.stripe.elements({ clientSecret });

    // Create the Payment Element and mount it
    this.paymentElement = this.elements.create("payment");
    this.paymentElement.mount(this.$refs.paymentElement);
  },

  methods: {
    submitForm() {
      this.$emit("next-step");
      console.log("Rohit", this.step);
    },
    goToPrevStep() {
      this.$emit("prev-step");
      console.log("Rohit", this.step);
    },

    async handleSubmit() {
      this.loading = true;
      this.errorMessage = "";

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: "http://localhost:8080/#/booking/confirmation", // Optional success page
        },
      });

      if (error) {
        this.errorMessage = error.message;
        this.loading = false;
      } else {
        this.submitForm();
      }
    },
  },
};
</script>

<style scoped>
.checkout {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.contact {
  margin: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: baseline;
}

.header {
  display: flex;
  padding: 20px 0;
  font-weight: bold;
}

.payment {
  padding: 0px;
  width: 100%;
  margin: 10px 0px;
}

.summary {
  padding: 0px 20px;
  width: 30%;
}

.guest-form {
  margin: 0px;
  padding: 20px;
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
}

#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.pay {
  margin: 20px 0;
}

button {
  background-color: #409eff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
