<template>
  <!-- <div class="header">
    <div class="logo">
      <img src="@/assets/logo.png" style="width: 40px; height: 40px" />
    </div>
    <div class="nav" v-if="step === 2">
      <el-button size="large" @click="goBack()" type="primary"
        >Back to Rooms</el-button
      >
    </div>
  </div> -->

  <Shop v-if="step === 1" @next-step="nextStep" />
  <Checkout v-if="step === 2" @next-step="nextStep" @prev-step="goBack" />
</template>

<script>
import Shop from "@/views/booking/Shop.vue";
import Checkout from "@/views/booking/Checkout.vue";

export default {
  name: "Booking",
  components: { Shop, Checkout },

  data() {
    return {
      step: 1,
    };
  },
  methods: {
    nextStep() {
      if (this.step < 3) {
        this.step++;
        console.log(this.step);
      }
    },

    goBack() {
      if (this.step > 1) {
        this.step--;
      }
    },
  },
};
</script>

<style>
.header {
  padding: 20px;
}
</style>
